import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'
import BPSImg from '../../images/services/BPS.jpg'



import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import SubIntro2 from '../../components/SubIntro2'
import FeaturesProductCard from '../../components/FeaturesProductCard'
import MirrorFeaturesProductCard from '../../components/MirrorFeaturesProductCard'

import LSMS1 from '../../images/Products/LSMS1.jpg'
import LSMS2 from '../../images/Products/LSMS2.jpg'
import LSMS3 from '../../images/Products/LSMS3.jpg'
import LSMS4 from '../../images/Products/LSMS4.jpg'

function LSMS_Product() {




  return (
    <div className='bg-white '>
      <HeroSubPages
        title="Logistics Services Management System"
        img={LSMS1}
        desc="Our Logistics Services Management System optimizes your supply chain with advanced tracking, efficient resource allocation, and streamlined operations. It ensures smooth management of logistics services, enhancing reliability and reducing costs for your business." />



      <SubIntro2 title={'Your reliable and cost-efficient logistics solution!'}
        desc1={'Managing logistics is a key variable to success in the fast-moving world we live in today. Our Logistics Management System (LMS) seeks to enhance your supply chain, drive costs down, and improve delivery times so you can stay ahead of the competition.'}
        desc2={'With our LSMS, you gain real-time visibility into your supply chain, enabling you to make informed decisions and respond swiftly to changing demands. '}
        desc3={'The system’s robust analytics and reporting tools provide actionable insights, allowing you to continuously refine and improve your logistics strategy. '}
        videoLink={'https://player.vimeo.com/video/1004594776?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22%20frameborder=%220%22%20allow=%22autoplay;%20fullscreen;%20picture-in-picture;%20clipboard-write'}

      />

      {/* Features of the  Product */}

      <MirrorFeaturesProductCard
        img={LSMS2}
        title={'Real-Time Tracking'}
        desc1={'Track shipments in real-time, from start-to-finish. Know where your goods are and when they will arrive, plus provide accurate information to your customers.'}
      />

      <div className="md:block hidden" >
        <FeaturesProductCard
          img={LSMS3}
          title={'Inventory Management'}
          desc1={'Maintain optimal inventory levels with intelligent inventory management tools. Avoid stockouts and overstocking issues through automation of alerts and reorder point calculations.'}
        />
      </div>
      <div className="md:hidden block" >

        <MirrorFeaturesProductCard
          img={LSMS3}
          title={"Inventory Management"}
          desc1={"Maintain optimal inventory levels with intelligent inventory management tools. Avoid stockouts and overstocking issues through automation of alerts and reorder point calculations."} />

      </div>

      <MirrorFeaturesProductCard
        img={LSMS4}
        title={'Reporting & Analytics'}
        desc1={'Gain insight into your logistics management process with very comprehensive reports. Analyze data to improve the decision-making process and recognize opportunities for improvement.'}
      />

      <CardSub />
      {/* <StatBox stats={statsData} /> */}
      {/* <PortfolioSub /> */}
      <TeamCards />
      {/* <SuopportCompanies/> */}


      <Footer />

    </div>
  )
}

export default LSMS_Product
