import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import Intro1 from '../components/Intro1';
import Intro2 from '../components/Intro2';
import Text_section from '../components/Text_section';


const Home = () => {
    return (
        <div className=''>
            <Hero />
            <Intro1 />
            <Intro videoType={'intro-video'}/>
            <Text_section/>
            <Services />
            <Intro2/>
            <Cta />
            <Portfolio />
            <Footer />
        </div>

    )
}

export default Home;

