import React from 'react';

function Intro1() {
  return (
    <div
      className="m-auto px-4 md:px-6 lg:px-8 py-6 md:py-8 lg:py-12 h-full max-w-7xl flex"
      id="about"
    >
      <div className="flex flex-col justify-center items-start text-start w-full">
        <p className="text-base text-lg md:text-xl  text-blue-800 font-semibold leading-relaxed">
          Welcome to Topfin Global! We are a dynamic team of experts dedicated to transforming your business with cutting-edge technology solutions. With a presence in over 10 countries, our mission is to enhance your operations and drive growth through innovative digital tools and services.
          <br />
          <br />
          Every day, our passionate professionals collaborate to deliver reliable and effective solutions for businesses and organizations worldwide. From advanced E-Wallet and E-Card systems to seamless digital payment solutions, we are committed to helping you achieve your goals.
          <br />
          <br />
          At Topfin Global, we focus on practical and impactful technology that meets your needs—from simplifying transactions to managing complex digital infrastructures. Our goal is to make a significant difference for your business and the communities we serve.
          <br />
          <br />
          <span className="text-blue-800 font-bold">Elevating What Matters</span>
        </p>
      </div>
    </div>
  );
}

export default Intro1;