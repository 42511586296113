import React from 'react';
import imageUrl from '../images/services/CMS1.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function MirrorFeaturesProductCard({ img, title, desc1 }) {
  return (
    <div className="bg-gray-100 mx-auto">
      <div
        className="m-auto p-4 md:py-8 h-5/6 lg:max-w-[1320px] flex flex-col items-center lg:items-start"
        id="about"
      >

        {/* Content Section: Title, Paragraph, and Image */}
        <div className="flex flex-col justify-center items-start text-start py-8 w-full">
          {/* Flex Container for Image and Paragraph */}
          <div className="flex flex-col lg:flex-row items-center lg:items-start w-full mt-2">
            {/* Image Box */}
            <div className="lg:mt-2 lg:mr-8 lg:w-1/2 w-full h-auto flex justify-center items-center">
              {/* <img
                        className="w-full h-auto object-cover shadow-lg transition-transform duration-500 ease-in-out hover:scale-105"
                        src={img} // Replace with your image URL or pass via props
                        alt="Descriptive Alt Text"
                    /> */}
            
                <LazyLoadImage
                  alt="Description"
                  src={img}  // Use optimized images
                  effect="blur"         // Can use effects like blur or opacity for better UX
                  className="w-full h-auto object-cover shadow-lg transition-transform duration-500 ease-in-out hover:scale-105" // Add your custom class here
                />



            </div>

            {/* Text Content */}
            <div className="lg:w-1/2 lg:pt-6">
              <h3 className="text-3xl lg:text-4xl font-bold py-4 text-transparent bg-clip-text bg-gradient-to-r from-[#115db4] to-[#359fce]">
                {title}
              </h3>
              {/* Paragraph Content */}
              <p className="lg:mt-12 text-normal lg:text-lg text-blue-800 font-semibold">
                {desc1}
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MirrorFeaturesProductCard
