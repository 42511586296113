import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import VideoCarousel from './VideoCarousel';

const Hero = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [fadeStyle, setFadeStyle] = useState({ opacity: 1 });

  // Function to handle button click and transition
  const handleClick = (index) => {
    setFadeStyle({ opacity: 0 }); // Start fade-out animation
    setTimeout(() => {
      setActiveIndex(index);
      setFadeStyle({ opacity: 1 }); // Fade back in after video change
    }, 800); // Adjust this duration to match the CSS transition time
  };

  // Automatically change video every few seconds
  useEffect(() => {
    const interval = setInterval(() => {
      handleClick((activeIndex + 1) % content.length);
    }, 5000); // Change video every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [activeIndex]);

  const content = [
    {
      title: 'Bespoke software solutions for your unique business needs',
      description: 'Skilled developers committed to delivering the best software.',
    },
    {
      title: 'Innovative Solutions for Modern Healthcare Challenges',
      description: 'Expertise in cutting-edge technology to enhance hospital management and patient care.',
    },
    {
      title: 'Transform Your Concepts into Innovation',
      description: 'Expert engineers delivering high-quality solutions to drive advancement in the automobile and manufacturing industries',
    },
    {
      title: 'Unlock Your Financial Potential with Expert E-Wallet and E-Card Solutions',
      description: 'Innovative solutions for seamless digital transactions and secure payment management.',
    },
  ];

  return (
    <>
      <div className="hero" id="hero">
        <NavBar />
        <div
          className="relative m-auto overflow-hidden mt-[60px] md:mt-24 h-[64vh] md:h-[64vh] lg:h-[64vh] xl:h-[68vh]"
          data-aos="zoom-in"
        >
          {/* Video Carousel */}
          <VideoCarousel activeIndex={activeIndex} className="w-full" />

          {/* Overlay */}
          <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>

          {/* Content Section */}
          <div
            id="hero-content"
            className="relative z-20 flex flex-col lg:flex-row h-full justify-center items-center lg:items-start py-12 lg:py-[10vh]  text-start lg:text-left"
          >
            <div
              className="w-full flex flex-col justify-center lg:max-w-[1320px] px-4 md:px-8 lg:px-12 mx-auto"
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <h1
                className="mb-4 md:mb-8 text-2xl md:text-4xl lg:text-4xl xl:text-6xl 2xl:7xl  font-bold text-white md:w-3/4"
                style={{ transition: 'opacity 0.8s ease-in-out', ...fadeStyle }}
              >
                {content[activeIndex].title}
              </h1>

              <div
                className="text-base text-lg md:text-xl lg:text-xl xl:text-3xl font-semibold tracking-tight mb-4 md:mb-5 text-white"
                style={{ transition: 'opacity 0.8s ease-in-out', ...fadeStyle }}
              >
                {content[activeIndex].description}
              </div>

              <div className="mt-6 md:mt-10 flex justify-start lg:justify-start">
                <Link
                  to="/contact"
                  style={{
                    transition: 'opacity 0.8s ease-in-out',
                    ...fadeStyle,
                  }}
                  className="font-bold text-white bg-gradient-to-r from-[#359fce] to-[#115db4] hover:bg-blue-800 inline-flex items-center justify-center w-auto px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0"
                >
                  Learn more
                  <svg
                    className="w-4 h-4 ml-1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </Link>
              </div>
            </div>
          </div>

          {/* Dots Navigation */}
          <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 z-20 flex space-x-2">
            {[0, 1, 2, 3].map((index) => (
              <span
                key={index}
                className={`w-3 h-3 sm:w-4 sm:h-4 md:w-5 md:h-5 rounded-full cursor-pointer transition-colors duration-300 ease-in-out ${activeIndex === index
                    ? 'bg-white border-white'
                    : 'bg-transparent border-2 border-white'
                  }`}
                onClick={() => handleClick(index)}
              ></span>
            ))}
          </div>
        </div>
      </div>


    </>
  );
};

export default Hero;
