import React from 'react'

function Text_section() {
    return (
        <>
            <div className="relative m-auto px-4 md:px-6 lg:px-8 py-6 md:py-8 lg:py-12 h-5/6 lg:max-w-[1320px] flex  " id='about'>



                <div className="text-container mb-20 flex flex-col justify-center items-start text-start py-8">
                    <h3 className="text-3xl md:text-4xl font-extrabold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-[#115db4] to-[#359fce]">
                        Building the Future with Innovative IT Solutions
                    </h3>
                    <p className="lg:text-lg text-normal font-semibold md:pr-[16%] text-[#196bb9]">
                        At Topfin Global, we believe that a strong foundation is key to successful technology solutions. It’s what turns challenges into opportunities and innovations into impactful results.
                        <br /><br />
                        Our approach to IT is all about creating dependable and adaptive solutions that drive seamless experiences and secure connections. We combine cutting-edge technology with expert craftsmanship to ensure that your business thrives in a digital world.
                        <br /><br />
                        Here’s how we make it happen:
                    </p>
                </div>

                <div className="absolute right-0 xl:bottom-6 lg:bottom-28 md:block hidden">
                    <svg className='xl:w-[600px] xl:h-[480px] lg:[520px] lg:h-[420px]' viewBox="0 0 302 238" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M300.5 3V1.5H299H193.881H193.344L192.929 1.84092L188.048 5.85065L184.811 8.50973H189H291.399V203.49H71.9915H71.5022L71.107 203.779L65.6155 207.789L61.902 210.5H66.5H299H300.5V209V3Z" fill="#B3CADC" stroke="#B3CADC" stroke-width="3" />
                        <path d="M281 24V20H277H154.356H152.924L151.817 20.9091L146.936 24.9188L138.303 32.0097H149.475H266.898V221.99H30.4915H29.1866L28.1327 222.76L22.6412 226.77L12.7387 234H25H277H281V230V24Z" fill="#034CA8" stroke="#034CA8" stroke-width="8" />
                    </svg>
                </div>



            </div>


        </>
    )
}

export default Text_section
