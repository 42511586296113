import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'
import BPSImg from '../../images/services/BPS.jpg'
import StatBox from '../../components/StatBox'


import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import SubIntro2 from '../../components/SubIntro2'
import FeaturesProductCard from '../../components/FeaturesProductCard'
import MirrorFeaturesProductCard from '../../components/MirrorFeaturesProductCard'

import CRMS1 from '../../images/Products/CRMS1.jpg'
import CRMS2 from '../../images/Products/CRMS2.jpg'
import CRMS3 from '../../images/Products/CRMS3.jpg'
import CRMS4 from '../../images/Products/CRMS4.jpg'



function CRMS_Product() {

  
  return (
    <div className='bg-white '>
      <HeroSubPages
        title="Criminal Record Management System"
        img={CRMS1}
        desc="Our Criminal Record Management System (CRMS) is designed to streamline the management and retrieval of criminal records. It provides a secure, centralized platform for storing, accessing, and updating criminal data efficiently." />



      <SubIntro2 title={'Manage your criminal records – simple, secure, and seamless'}
        desc1={'Having an effective way to manage criminal records is essential to maintaining public safety and holding offenders accountable.'}
        desc2={'. The Criminal Record Management System (CRMS) was created to assist law enforcement agencies and governmental and judicial agencies in managing the criminal reports, securely and proficiently!'}
        desc3={'Our CRMS offers advanced features such as secure data storage, real-time access, and comprehensive reporting tools, all designed to enhance the efficiency of record management. '}
        videoLink={'https://player.vimeo.com/video/1004594776?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22%20frameborder=%220%22%20allow=%22autoplay;%20fullscreen;%20picture-in-picture;%20clipboard-write'}

      />

      {/* Features of the  Product */}

      <MirrorFeaturesProductCard
        img={CRMS2}
        title={'Centralized Database'}
        desc1={'Store criminal records on one centralized, secured database. Provide real-time access to and updating of information for updates on all records.'}
      />

      <div className="md:block hidden" >
        <FeaturesProductCard
          img={CRMS3}
          title={'Automation of Records Entry'}
          desc1={'Save hours of valuable time by auto-creating data entry with reduced chances of human-made errors. Import your records from any source in any format with consistency and accuracy of data.'}
        />
      </div>
      <div className="md:hidden block" >

        <MirrorFeaturesProductCard
          img={CRMS3}
          title={"Automation of Records Entry"}
          desc1={"Save hours of valuable time by auto-creating data entry with reduced chances of human-made errors. Import your records from any source in any format with consistency and accuracy of data."} />

      </div>

      <MirrorFeaturesProductCard
        img={CRMS4}
        title={'Search and Retrieval'}
        desc1={'Powerful search capabilities to get criminal records in the blink of an eye. Search by name, ID, type of crime, date, and more-the information you want is at your fingertips.'}
      />

      <CardSub />
      {/* <StatBox stats={statsData} /> */}
      {/* <PortfolioSub /> */}
      <TeamCards />
      {/* <SuopportCompanies/> */}


      <Footer />

    </div>
  )
}

export default CRMS_Product
