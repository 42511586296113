import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link } from 'react-router-dom';

const Portfolio = () => {
    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                // Replace with your actual URL
                const response = await fetch('/api/blogslist.php?limit=6');

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                // Shuffle and select 4 random blogs
                const shuffled = data.sort(() => 0.5 - Math.random());
                setBlogs(shuffled.slice(0, 4));
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);


    return (
        <div className="my-4 py-4 lg:max-w-[1350px] mx-auto" id='portfolio'>
            <h2 className="my-2 mx-4 text-start text-3xl text-blue-900 font-bold">Stay Updated</h2>
            <div className="px-6 mt-12">
                <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
                    {blogs.map((blog, index) => (
                        <div key={index} className="bg-white overflow-hidden shadow-lg ">
                            {/* Image */}
                            {/* <img src={blog.image} alt="Card" className="w-full h-48 object-cover" /> */}

                            <LazyLoadImage
                                alt={`Card `}
                                src={blog.image}  // Use optimized images
                                effect="blur"         // Can use effects like blur or opacity for better UX
                                className="w-full h-48 object-cover" // Add your custom class here
                            />

                            {/* Content */}
                            <div className="p-6">
                                <h4 className="font-bold text-center text-2xl mb-4 text-blue-900 overflow-hidden text-ellipsis whitespace-nowrap">
                                    {blog.title}
                                </h4>
                                <p className="text-md text-center font-semibold text-gray-700 mb-6 overflow-hidden text-ellipsis">
                                    {blog.description}
                                </p>
                                <div className="text-center mt-4">
                                    <Link to={`/Blogs/${blog.id}`} className="px-6 py-3 font-bold bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 transition duration-300 text-white ">
                                        Learn More
                                    </Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Portfolio;
