import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';


export default function Cta() {
    const [swiperInstance, setSwiperInstance] = useState(null);

    const [currentIndex, setCurrentIndex] = useState(0);

    const navigateToSlide = (index) => {
        if (swiperInstance) {
            swiperInstance.slideTo(index);
            setCurrentIndex(index); // Update currentIndex to the new index
        }
    };


    return (
        <div className="relative">
            <Swiper
                onSwiper={(swiper) => setSwiperInstance(swiper)}
                loop={true}
                speed={2000}
                navigation={false} // Disable default navigation
                modules={[Navigation]}
                className="mySwiper2"
            >
                <SwiperSlide>
                    <div className="cta w-full flex items-center justify-center text-white">
                        <div className="mx-auto w-full max-w-[1320px] h-[360px] md:h-[720px] text-start lg:text-left py-16 md:px-12 flex  lg:justify-between items-center">
                            <div className="intro-services h-[360px] md:h-[720px] w-full lg:max-w-[800px] mx-auto flex flex-col lg:flex-row lg:justify-around">
                                <div className="mb-4   flex flex-col  justify-center w-full  lg:mt-0 mt-14 px-6 md:px-12">

                                    <Link to="ITSC-services"><h1 className='hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-[#36c1e1] hover:to-[#359fce] text-2xl md:text-5xl font-bold mb-8'>IT Solutions & Consulting</h1></Link>

                                    <Link to="EPC-product">   <p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">E-wallet and Prepaid card</p></Link>

                                    <Link to="ITSC-services" ><p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">IT Staffing & Staff Augmentations</p></Link>

                                    <Link to="ITSC-services">  <p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">Service Desk & Manage Services</p></Link>

                                    <Link to="IT-industries" >   <p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">Technology Consulting</p></Link>
                                </div>
                            </div>
                        </div>
                    </div>


                </SwiperSlide>
                <SwiperSlide>
                    <div className="cta1 w-full flex items-center justify-center text-white">
                        <div className="mx-auto w-full max-w-[1320px] h-[360px] md:h-[720px] text-start lg:text-left py-16 md:px-12 flex  lg:justify-between items-center">
                            <div className="intro-services h-[360px] md:h-[720px] w-full lg:max-w-[800px] mx-auto flex flex-col lg:flex-row lg:justify-around">
                                <div className="mb-4   flex flex-col  justify-center w-full  lg:mt-0 mt-14 px-6 md:px-12">
                                    <Link to="HMS-services"><h1 className='hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-[#36c1e1] hover:to-[#359fce] text-2xl md:text-5xl font-bold mb-8'>Healthcare Solutions and Services</h1></Link>

                                    <Link to="HMS-product">   <p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">Costomized Features</p></Link>

                                    <Link to="HMS-services" ><p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">Specialize Healthcare Solutions</p></Link>

                                    <Link to="HMS-services">  <p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">Maintenance & Support Services</p></Link>

                                    <Link to="HC-industries" >   <p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">Healthcare Consulting</p></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className="cta2 w-full flex items-center justify-center text-white">
                        <div className="mx-auto w-full max-w-[1320px] h-[360px] md:h-[720px] text-start lg:text-left py-16 md:px-12 flex  lg:justify-between items-center">
                            <div className="intro-services h-[360px] md:h-[720px] w-full lg:max-w-[800px] mx-auto flex flex-col lg:flex-row lg:justify-around">
                                <div className="mb-4   flex flex-col  justify-center w-full  lg:mt-0 mt-14 px-6 md:px-12">
                                    <Link to="FOS-services"><h1 className='hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-[#36c1e1] hover:to-[#359fce] text-2xl md:text-5xl font-bold mb-8'>Finanace And Other Support Services</h1></Link>

                                    <Link to="LMS-product">   <p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">Finanace Products</p></Link>

                                    <Link to="LMS-services" ><p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">Specialize Fintech Solutions</p></Link>

                                    <Link to="LMS-services">  <p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">Maintenance & Support Services</p></Link>

                                    <Link to="LMS-industries" >   <p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">Finanace Consulting</p></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>

                    <div className="cta3 w-full flex items-center justify-center text-white">
                        <div className="mx-auto w-full max-w-[1320px] h-[360px] md:h-[720px] text-start lg:text-left py-16 md:px-12 flex  lg:justify-between items-center">
                            <div className="intro-services h-[360px] md:h-[720px] w-full lg:max-w-[800px] mx-auto flex flex-col lg:flex-row lg:justify-around">
                            <div className="mb-4   flex flex-col  justify-center w-full  lg:mt-0 mt-14 px-6 md:px-12">
                                <Link to="BPS-services"><h1 className='hover:text-transparent hover:bg-clip-text hover:bg-gradient-to-r hover:from-[#36c1e1] hover:to-[#359fce] text-2xl md:text-5xl font-bold mb-8'>Bussiness Process Solutions</h1></Link>

                                <Link to="PAMS-product">   <p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">Back Office Process & Solutions</p></Link>

                                <Link to="BPS-services" ><p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">Data Analytics & Research</p></Link>

                                {/* <Link to="BPS-services">  <p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">Bussiness & Support Services</p></Link> */}

                                <Link to="BF-industries" >   <p className="text-lg md:text-3xl mb-2 hover:text-[#36c1e1] hover:font-bold">Bussiness Consulting</p></Link>
                            </div>
                            </div>
                        </div>
                    </div>

                </SwiperSlide>

            </Swiper>

            <div className="hidden md:flex absolute bottom-0 left-0 w-full justify-center items-center z-10">
                <div className="lg:max-w-[800px] w-full flex justify-between gap-0">

                    <button
                        onClick={() => navigateToSlide(0)}
                        className={`lg:leading-10 text-2xl flex-1 bg-black  ${currentIndex === 0 ? 'bg-opacity-80' : 'bg-opacity-50'} text-white px-8 py-6  hover:bg-opacity-75 transition`}
                    >
                        IT Solutions <br />& Consulting
                    </button>
                    <button
                        onClick={() => navigateToSlide(1)}
                        className={`lg:leading-10 text-2xl flex-1 bg-black  ${currentIndex === 1 ? 'bg-opacity-80' : 'bg-opacity-50'} text-white px-6 py-3  hover:bg-opacity-75 transition`}
                    >
                        Healthcare Solutions
                    </button>
                    <button
                        onClick={() => navigateToSlide(2)}
                        className={`lg:leading-10 text-2xl flex-1 bg-black  ${currentIndex === 2 ? 'bg-opacity-80' : 'bg-opacity-50'} text-white px-6 py-3  hover:bg-opacity-75 transition`}
                    >
                        Finance and Support
                    </button>
                    <button
                        onClick={() => navigateToSlide(3)}
                        className={`lg:leading-10 text-2xl flex-1 bg-black  ${currentIndex === 3 ? 'bg-opacity-80' : 'bg-opacity-50'} text-white px-6 py-3  hover:bg-opacity-75 transition`}
                    >
                        Bussiness Services
                    </button>
                </div>
            </div>
        </div>
    );
}