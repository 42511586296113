
import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'

import StatBox from '../../components/StatBox'

import Icon1 from '../../images/services/svg1.svg'; // Import SVG as ReactComponent
import Icon2 from '../../images/services/svg2.svg';
import Icon3 from '../../images/services/svg3.svg';
import Icon4 from '../../images/services/svg4.svg';
import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import Intro from '../../components/Intro'

import AA1 from '../../images/industries/AA1.jpg'

function AA_Industries() {

 

  return (
    <div className='bg-gray-100'>
      <HeroSubPages
        title="Agriculture and allied sector"
        img={AA1}
        desc="Transform the agriculture and allied sectors with our innovative IT solutions, designed to enhance productivity, streamline operations, and drive sustainable growth." />

      <SubIntro1 title={'Maximize Yield and Efficiency with Smart Agriculture'}
        desc1={'In the rapidly evolving world of agriculture and allied sectors, digital transformation is no longer a luxury but a necessity.'}
        desc2={' Our IT solutions have been developed to assist farmers, agribusinesses, and related industries with the latest technology that boosts dependability, productivity, and sustainability. Our smart farm management systems leverage artificial intelligence and machine learning to streamline operations. Our solutions provide accurate and efficient administration of all elements of farming, including computerized irrigation, pest control, animal monitoring, and resource allocation. '}
        desc3={'At TopFin, we pride ourselves on being a catalyst for positive change across diverse industries. Our holistic approach to cost management, IT solutions, and data analytics ensures that businesses not only survive but thrive in an increasingly competitive world.'} />

      <Intro videoType={"intro-AA-industries"}/>
    
      <CardSub />
      {/* <StatBox stats={statsData} /> */}
      {/* <PortfolioSub /> */}
      {/* <TeamCards /> */}
      {/* <SuopportCompanies/> */}


      <Footer />

    </div>
  )
}

export default AA_Industries

