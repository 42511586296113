import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';

// Service pages

import Cms from './pages/Services/Cms';
import Itsc from './pages/Services/Itsc';
import Fos from './pages/Services/Fos';
import Bps from './pages/Services/Bps';

// Product pages

import HMS_Product from './pages/Products/HMS_Product';
import FMS_Product from './pages/Products/FMS_Product';
import LSMS_Product from './pages/Products/LSMS_Product';
import LMS_Product from './pages/Products/LMS_Product';
import CRMS_Product from './pages/Products/CRMS_Product';
import DMS_Product from './pages/Products/DMS_Product';
import PAMS_Product from './pages/Products/PAMS_Product';
import EPC_Product from './pages/Products/EPC_Product';
import HC_Industries from './pages/Industries/HC_Industries';
import BF_Industries from './pages/Industries/BF_Industries';
import IT_Industries from './pages/Industries/IT_Industries';
import AM_Industries from './pages/Industries/AM_Industries';
import FMCG_Industries from './pages/Industries/FMCG_Industries';
import AV_Industries from './pages/Industries/AV_Industries';
import AA_Industries from './pages/Industries/AA_Industries';
import Blog from './pages/Blog';
import About from './pages/About';
import Page404 from './pages/Page404';

//Industries Pages




function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("Topfin Global");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />


              {/* Products Route  */}
            <Route path="/HMS-product" element={<HMS_Product />} />
            <Route path="/FMS-product" element={<FMS_Product />} />
            <Route path="/LSMS-product" element={< LSMS_Product/>} />
            <Route path="/LMS-product" element={<LMS_Product />} />
            <Route path="/CRMS-product" element={<CRMS_Product/>} />
            <Route path="/DMS-product" element={<DMS_Product/>} />
            <Route path="/PAMS-product" element={<PAMS_Product/>} />
            <Route path="/EPC-product" element={<EPC_Product/>} />

     

            {/* Sevices  Route */}
            <Route path="/CMS-services" element={<Cms />} />
            <Route path="/ITSC-services" element={<Itsc />} />
            <Route path="/FOS-services" element={<Fos />} />
            <Route path="/BPS-services" element={<Bps />} />


            {/* Industries Route */}

            <Route path="/HC-industries" element={<HC_Industries/>} />
            <Route path="/BF-industries" element={<BF_Industries/>} />
            <Route path="/IT-industries" element={<IT_Industries/>} />
            <Route path="/AM-industries" element={<AM_Industries/>} />
            <Route path="/FMCG-industries" element={<FMCG_Industries/>} />
            <Route path="/AV-industries" element={<AV_Industries/>} />
            <Route path="/AA-industries" element={<AA_Industries/>} />


            <Route path="/Blogs/:id" element={<Blog/>} />
            <Route path="/About-us" element={<About/>} />
            



            
            {/* Industries */}

            <Route path="/contact" element={<Contact />} />
            <Route path="/get-demo" element={<DemoProduct />} /> 
            <Route path="*" element={<Page404 />} /> 

          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
