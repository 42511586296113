import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'
import ITSCimg from '../../images/services/ITSC.jpg'
import StatBox from '../../components/StatBox'

import Icon1 from '../../images/services/svg11.svg'; // Import SVG as ReactComponent
import Icon2 from '../../images/services/svg22.svg';
import Icon3 from '../../images/services/svg33.svg';
import Icon4 from '../../images/services/svg44.svg';
import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'

function Itsc() {

  const statsData = [
    {
      svg: <img className='w-28 h-28' src={Icon1} alt="Projects Completed" />,
      title: 'Success Stories',
      value: 100,
      desc: "Client Successes That Drive Innovation"

    },
    {
      svg: <img className='w-28 h-28' src={Icon2} alt="Projects Completed" />,
      title: 'User Globally',
      value: 70,
      desc: "Empowering Users Across the Globe"
    },
    {
      svg: <img className='w-28 h-28' src={Icon3} alt="Projects Completed" />,
      title: 'Expert Advice',
      value: 30,
      desc: "Expert Insights Tailored to Your Needs"
    },
    {
      svg: <img className='w-28 h-28' src={Icon4} alt="Projects Completed" />,
      title: 'Years of Experience',
      value: 7,
      desc: "A Proven Track Record Spanning Years of Success"

    }
  ];


  return (
    <div className='bg-gray-100'>
      <HeroSubPages
        title="IT Solutions and Consultant"
        img={ITSCimg}
        desc="Optimize your organization's IT infrastructure and capabilities with our advanced IT Solutions and Consultancy services. Enhance your planning, implementation, and strategic decision-making processes to ensure that your technology resources are deployed efficiently and drive business growth." />


      <SubIntro1 title={'Transform Your Business with IT Expertise'} 
      desc1={'In a rapidly evolving technological landscape, IT Solutions are more than just a necessity—they’re a strategic advantage. At TopFin Global We’re passionate about turning technology into a strategic asset for your business.'} 
      desc2={'We offer best-in-class solutions that address your unique challenges and opportunities,and enable that every technology investment you make is a step towards achieving your strategic goals.'} 
      desc3={'Our IT Solutions and Consulting services are crafted to redefine how technology drives your business forward. Our approach to IT solutions is all about delivering value and efficiency. We focus on understanding your specific needs and crafting solutions that are as dynamic as your business.  we ensure your data is safe and secure by implementing advanced cybersecurity solutions which deliver both cost efficiency & scalability, tailoring flexible bespoke solutions.'}/>
     
      <CardSub/>
      <StatBox stats={statsData} />
      <PortfolioSub/>
      {/* <TeamCards/> */}
      {/* <SuopportCompanies/> */}


    <Footer/>

    </div>
  )
}

export default Itsc
