import React from 'react'

function StatBox({ stats,title }) {
    return (
        <div>
           <div className=" w-full h-5/6 bg-gradient-to-t from-[#00005c] to-[#00bfff]">
                <div className='lg:max-w-[1320px] mx-auto py-8 space-y-6 flex flex-col items-center px-4'>


                    <h3 className="text-4xl font-extrabold mb-8 text-white">
                     {title}
                    </h3>


                    <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1  gap-6'>


                        {stats.map((stat, index) => (
                            <div key={index} className="flex flex-col  justify-center items-center p-4 space-y-4">
                                <div className="mb-2">{stat.svg}</div>
                                <h3 className="text-2xl font-extrabold lg:text-start text-center text-white">{stat.title}</h3>
                                <p className="text-6xl font-bold text-white">{stat.value}+</p>
                                <p className="text-normal  text-white">{stat.desc}</p>
                            </div>
                        ))}


                    </div>

                </div>


            </div>

        </div>
    )
}

export default StatBox
