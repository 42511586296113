import React from 'react'
import imageUrl from '../images/services/CMS1.jpg'
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

function FeaturesProductCard({ img, title, desc1 }) {
    return (
        <div className='bg-white mx-auto'>
            <div
                className="m-auto p-4 md:py-8 h-5/6 lg:max-w-[1320px] flex flex-col items-center lg:items-start"
                id="about"
            >
                {/* <h2 className="my-2 text-start text-4xl text-blue-900 font-bold">Fetures Provides by us</h2> */}
                {/* Content Section: Title, Paragraph, and Image */}
                <div className="flex flex-col justify-center items-start text-start py-8 w-full">

                    {/* Title */}


                    {/* Flex Container for Paragraph and Image */}
                    <div className="flex flex-col lg:flex-row items-center lg:items-start w-full mt-2">
                        {/* Text Content */}
                        <div className="lg:w-1/2 lg:pt-6 p-4">
                            <h3 className="relative inline-block text-3xl lg:text-4xl font-bold py-4 text-transparent bg-clip-text bg-gradient-to-r from-[#115db4] to-[#359fce]">
                                {title}
                                {/* Optional: Gradient Line */}
                            </h3>

                            {/* Paragraph Content */}
                            <p className="lg:mt-6 text-normal lg:text-lg text-blue-800 font-semibold">
                                {desc1}
                                <br />
                                <span className="text-blue-800 font-bold">Advancing what matters</span>
                            </p>
                        </div>

                        {/* Image Box */}
                        <div className="mt-8 lg:mt-2 lg:ml-8 lg:w-1/2 w-full h-auto flex justify-center items-center">
                            {/* <img
                                className="w-full h-auto object-cover shadow-lg transition-transform duration-500 ease-in-out hover:scale-105"
                                src={img} // Replace with your image URL or pass via props
                                alt="Descriptive Alt Text"
                            /> */}

                            <LazyLoadImage
                                alt="Description"
                                src={img}  // Use optimized images
                                effect="blur"         // Can use effects like blur or opacity for better UX
                                className="w-full h-auto object-cover shadow-lg transition-transform duration-500 ease-in-out hover:scale-105" // Add your custom class here
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeaturesProductCard
