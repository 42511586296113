import React from 'react'

function Intro2() {
    return (
        <div className="m-auto px-6 py-4 md:p-4 md:py-8 h-5/6 lg:max-w-[1320px] flex" id='about'>
            <div className="flex flex-col justify-center items-start text-start py-8">
                <h3 className="text-3xl lg:text-4xl font-extrabold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-[#115db4] to-[#359fce]">
                    Elevating Your Business with Topfin Global
                </h3>
                <p className="text-lg text-blue-800 font-semibold">
                    At Topfin Global, we offer a comprehensive and modular portfolio designed to address your business's operational, digital, and human capital needs. Our Technology Services encompass expert advisory and professional services, extending to advanced solutions such as AI, data analytics, automation, and IoT. With our Cloud & Infrastructure capabilities, we manage, optimize, and modernize mission-critical operations across the cloud continuum, from the edge to public cloud and everything in between. Our Digital Business Platforms provide cutting-edge solutions, including generative AI, sustainable IT, and major event management. Through our Digital Workplace services, we enhance end-to-end employee experiences with digital collaboration tools and intelligent customer care solutions.
                </p>
            </div>
        </div>
    )
}

export default Intro2
