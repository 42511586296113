import React from 'react';
import { Link } from 'react-router-dom';
import TreePlant from '../images/tree.jpg'
import Server from '../images/servers.jpg'
import Partner from '../images/partner.jpg'
import Img from '../images/male-doctor-taking-notes-while-working-with-colleagues-medical-clinic.jpg'

const TeamCards = () => {

    const cards = [
        {
            image: Server,
            title: 'Atos Inno’labs: Co-innovation for the future of your business.',

        },
        {
            image: TreePlant,
            title: 'Sovereign AI',

        },
      
    ];

    return (
        <>
            <div className="my-4 py-4  lg:max-w-[1350px] mx-auto" id='portfolio '>
                <h2 className="px-4 text-start text-3xl text-blue-900  font-bold">Our Experts</h2>

                <div className="px-4 mt-12" data-aos="fade-down" data-aos-delay="600">
                    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-3">


                        {cards.map((card, index) => (
                            <div className="flex items-center justify-center flex-col  p-5  w-[360px] h-full bg-white  ">
                                <img
                                    src="https://images.pexels.com/photos/774909/pexels-photo-774909.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260"
                                    alt="Olivia Rhye"
                                    className="lg:w-[160px] lg:h-[160px] w-24 h-24  rounded-full object-cover transition duration-200 hover:scale-110"
                                />

                                <div className="text-gray-900 text-lg lg:text-xl font-bold mt-6">Stéphane Richard</div>

                                <div className="text-blue-600 text-center font-semibold">Global Head of Technology Services</div>

                                          
                            </div>

                        ))}

                    </div>
                </div>
            </div>
        </>
    )
}

export default TeamCards;