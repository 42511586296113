import React, { useState } from 'react'
import NavBar from '../components/Navbar/NavBar'

import Image from '../images/AboutUs1.jpg'
import AboutTopfin from '../images/AboutUs.jpg'
import AboutUsMission from '../images/AboutUsMission.jpg'
import AboutUsVision from '../images/AboutUsVision.jpg'

import FeaturesProductCard from '../components/FeaturesProductCard';
import MirrorFeaturesProductCard from '../components/MirrorFeaturesProductCard';
import Footer from '../components/Footer';

function About() {

    const [activeIndex, setActiveIndex] = useState(0);
    const [fadeStyle, setFadeStyle] = useState({ opacity: 1 });
    return (
        <div className="hero" id='hero'>
            <div>
                <NavBar />
            </div>

            <div className="relative m-auto overflow-hidden mt-18 md:mt-24 h-5/6" data-aos="zoom-in">
                {/* Video Carousel */}
                {/* <VideoCarousel activeIndex={activeIndex} /> */}
                <img className={`absolute top-0 left-0 w-full h-[620px] object-cover transition-opacity duration-500 `} src={Image} alt="About Us" />

                {/* Overlay */}
                <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>

                <div id='hero' className=" relative z-20 h-[620px] flex lg:flex-row  mx-12  justify-between text-center lg:text-left">

                    <div className='col-span-2'></div>
                    <div className="col-span-2 w-full flex flex-col justify-center  lg:max-w-[1320px] mx-auto" data-aos="zoom-in" data-aos-delay="200">

                        <h1 className="mb-8  md:text-7xl text-5xl font-extrabold text-white lg:w-3/4 "
                            style={{ transition: 'opacity 0.8s ease-in-out', ...fadeStyle }}>
                            About us
                        </h1>

                        <div className="text-xl font-semibold tracking-tight mb-5 text-white lg:w-2/4"
                            style={{ transition: 'opacity 0.8s ease-in-out', ...fadeStyle }}>
                            TopFin Global is a leading company in the IT industry, renowned for delivering innovative technology solutions.we help businesses navigate the digital landscape, enhancing their operations through advanced IT infrastructure and cutting-edge software.
                        </div>



                    </div>
                </div>


            </div>

            <div className="relative md:mt-8 m-auto p-4 md:py-8 h-5/6 lg:max-w-[1320px] flex  " id='about'>

                <div className="hidden md:block  absolute top-2 left-0 bottom-0 md:block hidden">
                    <svg width="600" height="400" viewBox="0 0 302 238" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M108.345 1.5H108.881L109.296 1.83991L114.188 5.84965L117.433 8.50973H113.237H10.6148V203.49H230.497H230.985L231.38 203.778L236.883 207.788L240.606 210.5H236H3H1.5V209V3V1.5H3H108.345Z" fill="#B3CADC" stroke="#B3CADC" stroke-width="3" />
                        <path d="M147.644 20H149.076L150.183 20.9091L155.064 24.9188L163.697 32.0097H152.525H35.1017V221.99H271.508H272.813L273.867 222.76L279.359 226.77L289.261 234H277H25H21V230V24V20H25H147.644Z" fill="url(#paint0_linear_0_1)" stroke="url(#paint1_linear_0_1)" stroke-width="8" />
                        <defs>
                            <linearGradient id="paint0_linear_0_1" x1="151" y1="24" x2="151" y2="230" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#034CA8" />
                                <stop offset="1" stop-color="#52CDDC" />
                            </linearGradient>
                            <linearGradient id="paint1_linear_0_1" x1="151" y1="24" x2="151" y2="230" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#034CA8" />
                                <stop offset="1" stop-color="#52CDDC" />
                            </linearGradient>
                        </defs>
                    </svg>

                </div>

                <div className="text-container md:mt-[40px] md:ml-[140px] mb-8 flex flex-col justify-center items-start text-start py-8">
                    {/* <h3 className="text-4xl font-extrabold mb-8 text-transparent bg-clip-text bg-gradient-to-r from-[#115db4] to-[#359fce]">
                        We create the foundations for a thriving tomorrow
                    </h3> */}
                    <p className="text-xl font-semibold pr-[16%]  text-[#196bb9]">
                        Cost plus is the preferred choice of many business houses to reduce the projected operating expenditure of any start up and established business units.
                        <br /><br />
                        TopFin provides Cost Plus solutions at the lowest rate in the market and take the ownership of establishing a full fledged Delivery Centre with full team of skilled professionals and providing them end to end support for delivering respective services to the client.
                    </p>
                </div>





            </div>

            <FeaturesProductCard img={AboutTopfin} title={"About Topfin"} desc1={"TopFin Global is an IT Company incorporated in 2023 in India. The company aims to reduce the cost of technological upgrade, implementation and all aspect of IT services of an organization substantially to help the new age entrepreneurs and businesses to run profitably without investing a lot on IT function which takes away a major part of IT budget affecting cash flow and net margin of the organization."} />
           
        
            <div className="md:hidden block" >
            <FeaturesProductCard img={AboutUsVision} title={"Our Vision"} desc1={"TopFin realizes the struggle, business owners and key executives face to manage their IT cost with limited IT budget. So, we see a future where cost of running a business comes down drastically through our innovative low cost IT services with lower charge mechanism to help business owners and C level executives to increase their bottom line."} />
            </div>
            
            <div className="md:block hidden" >
            <MirrorFeaturesProductCard  img={AboutUsVision} title={"Our Vision"} desc1={"TopFin realizes the struggle, business owners and key executives face to manage their IT cost with limited IT budget. So, we see a future where cost of running a business comes down drastically through our innovative low cost IT services with lower charge mechanism to help business owners and C level executives to increase their bottom line."} />
            </div>
            
            <FeaturesProductCard img={AboutUsMission} title={"Our Mission"} desc1={"Our mission is to provide the best services and to optimize the IT cost by evaluating the cost of same service in different parts of the world and choose the lowest one with superior quality."} />


            <Footer />
        </div>
    )
}

export default About
