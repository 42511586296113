
import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'

import StatBox from '../../components/StatBox'

import Icon1 from '../../images/services/svg1.svg'; // Import SVG as ReactComponent
import Icon2 from '../../images/services/svg2.svg';
import Icon3 from '../../images/services/svg3.svg';
import Icon4 from '../../images/services/svg4.svg';
import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import Intro from '../../components/Intro'

import AM1 from '../../images/industries/AM1.jpg'

function AM_Industries() {


  return (
    <div className='bg-gray-100'>
      <HeroSubPages
        title="Automobile Industries"
        img={AM1}
        desc="Discover how our IT solutions are revolutionizing the way vehicles are designed, manufactured, and driven." />

      <SubIntro1 title={'Driving Innovation in the Automotive Industry'}
        desc1={"In a field where innovation and technology fuel advancement, TopFin Global provides customized IT solutions that address the particular requirements of the automobile industry."}
        desc2={" Our innovative IT services enable automotive firms to remain ahead of the curve by streamlining supply chains, boosting customer experiences, and upgrading manufacturing processes."}
        desc3={"Our automotive IT solutions include smart manufacturing and automation, which use IoT, AI, and robotics to improve production line precision and efficiency. We provide innovative connected vehicle solutions that improve telematics, infotainment, and communication in cars, making them smarter and safer. Our data analytics and artificial intelligence (AI) services transform massive volumes of vehicle data into actionable insights, enhancing performance and forecasting maintenance requirements. We use real-time tracking and predictive analytics to simplify supply chains, and our rigorous cybersecurity procedures secure linked cars and IT systems. In addition, we improve client experiences with digital platforms and assist autonomous car development with innovative software and simulation tools."} />

      <Intro videoType={"intro-AM-industries"}/>
    
      <CardSub />
      {/* <StatBox stats={statsData} /> */}
      {/* <PortfolioSub /> */}
      {/* <TeamCards /> */}
      {/* <SuopportCompanies/> */}


      <Footer />

    </div>
  )
}

export default AM_Industries

