import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'
import BPSImg from '../../images/services/BPS.jpg'
import StatBox from '../../components/StatBox'

import Icon1 from '../../images/services/svg222.svg'; // Import SVG as ReactComponent
import Icon2 from '../../images/services/svg3.svg';
import Icon3 from '../../images/services/svg44.svg';
import Icon4 from '../../images/services/svg111.svg';
import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'

function Bps() {

  const statsData = [
    {
      svg: <img className='w-28 h-28' src={Icon1} alt="Projects Completed" />,
      title: 'Business Technology',
      value: 15,
      desc: "Advanced Technology Solutions for Businesses"

    },
    {
      svg: <img className='w-28 h-28' src={Icon2} alt="Projects Completed" />,
      title: 'Partenrs',
      value: 10,
      desc: "Strategic and Trusted Business Partners for Growth"
    },
    {
      svg: <img className='w-28 h-28' src={Icon3} alt="Projects Completed" />,
      title: 'Satisfied Customer',
      value: 120,
      desc: "Committed to Delivering Customer Satisfaction"
    },
    {
      svg: <img className='w-28 h-28' src={Icon4} alt="Projects Completed" />,
      title: 'Clients',
      value: 100,
      desc: "Building Strong Relationships with Our Clients"

    }
  ];


  return (
    <div className='bg-gray-100'>
      <HeroSubPages
        title="Business Process Solution"
        img={BPSImg}
        desc="Optimize your organization's operations and performance with our advanced Business Process Solutions and Services. Enhance planning, forecasting, and decision-making processes to ensure that your workflows are streamlined, and resources are utilized effectively and efficiently" />



      <SubIntro1 title={'Elevate Your Business with Our Customized Business '}
        desc1={'We believe that the main key to a thriving business lies in mastering its processes. Our Business Process Solutions provide World-class technical excellence to help you to accept each an every business related problem as well as elevating the business. We transform complex operations into smooth,'}
        desc2={'Efficient processes that allows  you to focus on what truly matters—delivering exceptional value to your customers. We ensure operational excellence through our undisputed process management techniques for any company in any industry.'}
        desc3={'The Business Process Solution is a new way of doing things by using intelligent automation to perform high-volume, low-value tasks that consume significant team attention in manual processes. These solutions integrate flawlessly with your systems and not only enhance how you operate within it, but also enable reduced overheads as well as long term scalability.'} />

      <CardSub />
      <StatBox stats={statsData} />
      <PortfolioSub />
      {/* <TeamCards /> */}
      {/* <SuopportCompanies/> */}


      <Footer />

    </div>
  )
}

export default Bps
