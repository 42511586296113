import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, EffectCoverflow, Pagination } from 'swiper/modules';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// Import Images
import Img from '../images/male-doctor-taking-notes-while-working-with-colleagues-medical-clinic.jpg';
import TreePlant from '../images/tree.jpg';
import Office_team from '../images/office_team.jpg';
import Olympic from '../images/Symbol_Edited_Images/Sports.png';
import { Link } from 'react-router-dom';

const Services = () => {
    const [swiperInstance, setSwiperInstance] = useState(null);
    const [activeIndex, setActiveIndex] = useState(0);

    const navigateToSlide = (index) => {
        if (swiperInstance) {
            swiperInstance.slideTo(index);
            setActiveIndex(index);
        }
    };

    return (
        <div id="services" className="bg-gray-100 py-12">
            <section>
                <div className="md:px-12 px-6 mx-auto">
                    <Swiper
                        onSwiper={(swiper) => setSwiperInstance(swiper)}
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        loop={true}
                        spaceBetween={-100}
                        breakpoints={{
                            768: {
                                spaceBetween: -100,
                            },
                            1024: {
                                spaceBetween: -200,
                            },
                            1280: {
                                spaceBetween: -300,
                            },
                            1440:{

                                spaceBetween:-600,
                            }
                        }}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 0,
                            modifier: 1,
                            slideShadows: false,
                            scale: 0.8,
                        }}
                        modules={[EffectCoverflow, Navigation, Pagination]}
                        className="mySwiper"
                    >
                        {[Img, TreePlant, Office_team, Olympic].map((image, index) => (
                            <SwiperSlide key={index}>
                                <div className="relative md:max-w-[720px] lg:max-w-[920px] xl:max-w-[1040px] mx-auto overflow-hidden shadow-2xl group">
                                    <div className="relative w-full h-[600px] md:h-[600px]">
                                        {/* <img
                                            src={image}
                                            alt={`Background ${index}`}
                                            className="w-full h-full object-cover rounded-t transition-transform duration-1000 ease-in-out group-hover:scale-[1.15]"
                                            loading="lazy" // Lazy load images
                                        /> */}

                                        <LazyLoadImage
                                            alt={`Background ${index}`}
                                            src={image}  // Use optimized images
                                            effect="blur"         // Can use effects like blur or opacity for better UX
                                            className="w-full h-full object-cover rounded-t transition-transform duration-1000 ease-in-out group-hover:scale-[1.15]" // Add your custom class here
                                        />

                                        <div className="absolute inset-0 bg-gradient-to-r from-gray-900 via-gray-500 to-transparent opacity-40 pointer-events-none transition-opacity duration-1000 ease-in-out group-hover:opacity-0"></div>
                                    </div>
                                    <div className="absolute inset-0 flex flex-col justify-between p-4 md:p-6 text-white bg-gray-900 bg-opacity-50 grid grid-cols-1 md:grid-cols-3">
                                        <div className="mt-12 md:mt-0 lg:px-[30px] lg:py-[60px] mx-auto justify-start col-span-1 md:col-span-2 text-center md:text-left">
                                            <h2 className="font-bold text-3xl md:text-4xl xl:text-5xl mb-4 lg:mb-10">
                                                {index === 0 && 'Innovating Healthcare for a Healthier Tomorrow'}
                                                {index === 1 && 'Transforming Agriculture for a Sustainable Future'}
                                                {index === 2 && 'Innovative IT Solutions for Modern Businesses'}
                                                {index === 3 && 'Driving Innovation in FMCG Industries'}
                                            </h2>
                                            <p className="text-lg md:text-xl xl:text-2xl font-medium lg:mb-8 mb-4">
                                                {index === 0 && 'At Topfin Global, we believe in revolutionizing healthcare to provide better patient outcomes and operational efficiency. Our solutions are designed to meet the dynamic needs of hospitals and healthcare providers, enabling them to offer quality care, streamline processes, and enhance patient experiences. Together, we build a healthier future for all.'}
                                                {index === 1 && 'At Topfin Global, we are committed to driving innovation in the agriculture and allied sectors. Our solutions help farmers and agricultural businesses leverage advanced technology and sustainable practices to increase productivity, reduce waste, and promote environmental stewardship. Together, we cultivate a prosperous and sustainable future for agriculture.'}
                                                {index === 2 && 'At Topfin Global, we provide cutting-edge IT solutions that drive digital transformation and business growth. From cloud computing and cybersecurity to custom software development, our comprehensive IT services are designed to empower businesses to navigate the complexities of the digital landscape and achieve operational excellence.'}
                                                {index === 3 && 'At Topfin Global, we partner with leading FMCG companies to drive growth and innovation. Our expertise in consumer insights, supply chain optimization, and digital transformation enables FMCG businesses to stay ahead of the competition and meet the evolving needs of consumers in a rapidly changing market.'}
                                            </p>
                                            <Link to={`/${['HC', 'AA', 'IT', 'FMCG'][index]}-industries`} className={`px-4 md:px-6 py-2 md:py-3 font-bold ${index === 0 ? 'bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800' : index === 1 ? 'bg-gradient-to-r from-green-500 to-green-700 hover:from-green-600 hover:to-green-800' : 'bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800'} transition duration-300`}>
                                                Learn More
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>
            </section>

            <div className="mt-8 z-10 flex space-x-2 items-center justify-center">
                {[0, 1, 2, 3].map((index) => (
                    <span
                        key={index}
                        className={`w-5 h-5 rounded-full cursor-pointer transition-colors duration-300 ease-in-out ${activeIndex === index ? 'bg-blue-600 border-gray-800' : 'bg-transparent border-2 border-gray-800'}`}
                        onClick={() => navigateToSlide(index)}
                    ></span>
                ))}
            </div>
        </div>
    );
};

export default Services;






