import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import NavBar from '../components/Navbar/NavBar';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const HeroSubPages = ({ img, title, desc }) => {

    // State to track the active button
    const [activeIndex, setActiveIndex] = useState(0);
    const [fadeStyle, setFadeStyle] = useState({ opacity: 1 });


    return (
        <>
            <div className="hero" id='hero'>
                <div>
                    <NavBar />
                </div>

                <div className="relative m-auto overflow-hidden mt-[60px] md:mt-24 h-5/6" data-aos="zoom-in">
                    {/* Video Carousel */}
                    {/* <VideoCarousel activeIndex={activeIndex} /> */}
                    <img className={`absolute top-0 left-0 w-full h-[620px] object-cover transition-opacity duration-500 `} src={img} alt="" />
{/* 
                    <LazyLoadImage
                        alt="Description"    
                        src={}  // Use optimized images              
                        effect="blur"         // Can use effects like blur or opacity for better UX
                        className="my-custom-class" // Add your custom class here
                    /> */}

                    {/* Overlay */}
                    <div className="absolute inset-0 bg-black bg-opacity-50 z-10"></div>

                    <div id='hero' className=" relative z-20 lg:h-[620px] h-[500px]  flex flex-col lg:flex-row    px-6 md:px-8 lg:px-8 items-center justify-center text-center lg:text-left">
                        <div className="w-full flex flex-col justify-center px-6 md:px-8   max-w-[1320px] mx-auto" data-aos="zoom-in" data-aos-delay="200">

                            <h1 className="mb-8  md:text-5xl lg:text-5xl  xl:text-7xl text-4xl font-extrabold text-white xl:w-3/4 "
                                style={{ transition: 'opacity 0.8s ease-in-out', ...fadeStyle }}>
                                {title}
                            </h1>

                            <div className=" md:text-lg xl:text-xl text-normal font-semibold tracking-tight mb-5 text-white lg:w-2/4"
                                style={{ transition: 'opacity 0.8s ease-in-out', ...fadeStyle }}>
                                {desc}
                            </div>

                            {/* <div className="mt-12 space-x-0 md:space-x-2 md:mb-8">
                                {activeIndex === 0 ?
                                    <Link to="/contact" style={{ transition: 'opacity 0.8s ease-in-out', ...fadeStyle }} className="font-bold text-white bg-gradient-to-r from-[#359fce] to-[#115db4] hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                        Learn more
                                        <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </Link>
                                    : activeIndex === 1 ?
                                        <Link to="/contact" style={{ transition: 'opacity 0.8s ease-in-out', ...fadeStyle }} className="font-bold text-white bg-gradient-to-r from-[#359fce] to-[#115db4] hover:bg-blue-800 inline-flex items-center justify-center w-full px-6 py-3 my-4 text-lg shadow-xl rounded-2xl sm:w-auto sm:mb-0">
                                            Learn more
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                        :
                                        <Link to="/contact" style={{ transition: 'opacity 0.8s ease-in-out', ...fadeStyle }} className="font-extrabold text-white bg-transparent border-2 border-white hover:bg-white hover:text-black inline-flex items-center justify-center w-full px-6 py-2 my-2 text-lg shadow-xl rounded-full sm:w-auto sm:mb-0">
                                            Find out more
                                            <svg className="w-4 h-4 ml-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                        </Link>
                                }
                            </div> */}

                        </div>
                    </div>

                    {/* Dots Navigation */}
                    {/* <div className="absolute bottom-5 left-1/2 transform -translate-x-1/2 z-20 flex space-x-2">
                        {[0, 1, 2 , 3].map((index) => (
                            <span
                                key={index}
                                className={`w-5 h-5 rounded-full cursor-pointer transition-colors duration-300 ease-in-out
                              ${activeIndex === index ? 'bg-white border-white' : 'bg-transparent border-2 border-white'}`}
                                onClick={() => handleClick(index)}
                            ></span>
                        ))}
                    </div> */}
                </div>
            </div>
        </>
    )
}

export default HeroSubPages;