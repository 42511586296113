import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'

import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import SubIntro2 from '../../components/SubIntro2'
import FeaturesProductCard from '../../components/FeaturesProductCard'
import MirrorFeaturesProductCard from '../../components/MirrorFeaturesProductCard'

import HMS1 from '../../images/Products/HMS1.jpg'
import HMS2 from '../../images/Products/HMS2.jpg'
import HMS3 from '../../images/Products/HMS3.jpg'
import HMS4 from '../../images/Products/HMS4.jpg'


function HMS_Product() {




  return (
    <div className='bg-white '>
    <HeroSubPages
      title="Hospital 
      Management System"
      img={HMS1}
      desc="Our Hospital Management System (HMS) streamlines every aspect of hospital operations, from patient registration to discharge. It simplifies processes, improves efficiency, and ensures a seamless experience for both staff and patients, allowing your team to focus on delivering exceptional care." />



    <SubIntro2 title={'From registration to discharge, our HMS simplifies your care'}
      desc1={'Unlock the complete potential of your healthcare services with our advanced Hospital Management System (HMS). Built to improve workflows, enhance patient care, and improve efficiency,'}
      desc2={'our HMS provides one holistic solution for contemporary hospitals and clinics.'}
      desc3={'Manage every aspect of your hospital on one platform,Simple to navigate to decrease training time and allow for higher productivity,The solution can be modified to accommodate the needs of your health care institution as a whole,Patients information confidentiality needs to be maintained, while keeping in mind any laws governing hospitals.'}
      videoLink={'https://player.vimeo.com/video/1004594776?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22%20frameborder=%220%22%20allow=%22autoplay;%20fullscreen;%20picture-in-picture;%20clipboard-write'}

    />

    {/* Features of the  Product */}

    <MirrorFeaturesProductCard
      img={HMS2}
      title={'Outpatient Management System (OPMS)'}
      desc1={'Effectively manage outpatient registration, appointment management, billing management, and medical history management.'}
    />

    <div className="md:block hidden" >
      <FeaturesProductCard
        img={HMS3}
        title={'Inpatient Management System (IPMS)'}
        desc1={'Move patients easily through bed management, patient monitoring, care planning and discharge management.'}
      />
    </div>
    <div className="md:hidden block" >

      <MirrorFeaturesProductCard
        img={HMS3}
        title={"Inpatient Management System (IPMS)"}
        desc1={"Move patients easily through bed management, patient monitoring, care planning and discharge management."} />

    </div>

    <MirrorFeaturesProductCard
      img={HMS4}
      title={'Medicine Module'}
      desc1={'Overseeing medication inventory management, pharmacy management, and medication dispensing to manage inventory and minimize errors.'}
    />

    <CardSub />
    {/* <StatBox stats={statsData} /> */}
    {/* <PortfolioSub /> */}
    {/* <TeamCards /> */}
    {/* <SuopportCompanies/> */}


    <Footer />

  </div>
  )
}

export default HMS_Product
