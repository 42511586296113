import React, { useState } from 'react';
import img from '../images/company_intro.jpg';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import IntroImage from '../images/company_intro.jpg'

Modal.setAppElement('#root');

const Intro = ({ videoType }) => {

    const [isOpen, setIsOpen] = useState(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };
    return (

        <>
            {/* VIDEO SECTION */}

            <div className={`relative w-full flex items-center justify-center text-white ${videoType} z-0`}>
                <div className="mx-8 w-full md:h-[620px] h-[500px] text-center lg:text-left py-16 px-12 flex lg:justify-between items-center">
                    <div className="w-full flex flex-col lg:flex-row lg:justify-around items-center">
                        <div className="relative w-full lg:w-72 pt-6 lg:mx-12 flex justify-center">
                            <div className="relative w-full h-[420px] flex items-center justify-center">
                                {/* Video Button */}
                                <button
                                    onClick={openModal}
                                    className="w-20 h-20 bg-[#115db4] rounded-full ring-2 ring-white grid place-items-center hover:bg-[#3198cb] transition"
                                    aria-label="Watch the video"
                                >
                                    <svg
                                        className="ml-1 w-8"
                                        viewBox="0 0 16 18"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M15 7.26795C16.3333 8.03775 16.3333 9.96225 15 10.7321L3 17.6603C1.66667 18.4301 1.01267e-06 17.4678 1.07997e-06 15.9282L1.68565e-06 2.0718C1.75295e-06 0.532196 1.66667 -0.430054 3 0.339746L15 7.26795Z"
                                            fill="white"
                                        />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={isOpen}
                    onRequestClose={closeModal}
                    contentLabel="Company Video"
                    className="modal-content fixed z-50 w-full h-full flex items-center justify-center"
                    overlayClassName="modal-overlay fixed inset-0 z-40 bg-black bg-opacity-75 transition-opacity duration-300 ease-in-out"
                    shouldCloseOnOverlayClick={true} // Ensures modal closes when clicking outside the video
                >
                    <button
                        onClick={closeModal}
                        className="cursor-pointer absolute top-0 right-0 z-50 p-4 text-xl text-white"
                    >
                        ✖
                    </button>
                    <div className="flex justify-center items-center w-full h-full" onClick={closeModal}>
                        <iframe
                            width="853"
                            height="480"
                            src="https://player.vimeo.com/video/1004594776?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22%20frameborder=%220%22%20allow=%22autoplay;%20fullscreen;%20picture-in-picture;%20clipboard-write"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Company Video"
                            className="rounded-lg shadow-xl"
                        ></iframe>
                    </div>
                </Modal>
            </div>

            {/* TEXT SECTION */}




        </>
    )
}

export default Intro;