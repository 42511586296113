
import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'

import StatBox from '../../components/StatBox'

import Icon1 from '../../images/services/svg1.svg'; // Import SVG as ReactComponent
import Icon2 from '../../images/services/svg2.svg';
import Icon3 from '../../images/services/svg3.svg';
import Icon4 from '../../images/services/svg4.svg';
import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import Intro from '../../components/Intro'

import AV1 from '../../images/industries/AV1.jpg'

function AV_Industries() {

  const statsData = [
    {
      svg: <img className='w-28 h-28' src={Icon1} alt="Projects Completed" />,
      title: 'Scale',
      value: 120,
      desc: "clients worldwide"

    },
    {
      svg: <img className='w-28 h-28' src={Icon2} alt="Projects Completed" />,
      title: 'Active Clients',
      value: 45,
      desc: "dedicated business technologists"
    },
    {
      svg: <img className='w-28 h-28' src={Icon3} alt="Projects Completed" />,
      title: 'Team Members',
      value: 30,
      desc: "innovation labs delivering co-creation services"
    },
    {
      svg: <img className='w-28 h-28' src={Icon4} alt="Projects Completed" />,
      title: 'Years of Experience',
      value: 10,
      desc: "countries where we deliver services"

    }
  ];


  return (
    <div className='bg-gray-100'>
      <HeroSubPages
        title="Aviation Industries"
        img={AV1}
        desc="Enhance every journey with our intelligent solutions designed to elevate the flight experience" />

      <SubIntro1 title={'Elevate Every Flight with Our Smart Solutions'}
        desc1={'In an industry where precision, safety, and innovation intersect, TopFin Global stands at the forefront, offering bespoke IT solutions tailored to the specific demands of the aviation sector.'}
        desc2={'We are more than just an IT service provider; we are your creative partner, promoting change and excellence in all parts of your organization. Our comprehensive IT solutions are designed to assist airlines, airports, and aviation service providers in overcoming the specific challenges they face. We do more than just provide services; we help the aviation sector soar higher, quicker, and smarter. Welcome to the future of flight, where technology is taking off. Industry will soar higher, quicker, and smarter. Welcome to the future of flying, where technology takes off.'}
        desc3={'Our IT solutions empower the agriculture business through the use of sophisticated instruments designed for efficient supply chain operations, precision farming, and smart farm management. We employ cutting edge technologies, which include IoT, AI, and data analytics, to enhance supply chain traceability, increase agricultural output, and simplify farm operations.'} />

      <Intro videoType={"intro-AV-industries"}/>
    
      <CardSub />
      {/* <StatBox stats={statsData} /> */}
      {/* <PortfolioSub /> */}
      {/* <TeamCards /> */}
      {/* <SuopportCompanies/> */}


      <Footer />

    </div>
  )
}

export default AV_Industries

