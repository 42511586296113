import React from 'react'

function SubIntro1({ title, desc1, desc2, desc3 }) {
  return (
    <div className="m-auto p-4 md:py-8 h-5/6 lg:max-w-[1320px] flex  px-6 md:px-8 lg:px-8" id='about'>
      <div className="flex flex-col justify-center items-start text-start py-8">
        <h3 className="text-3xl md:text-4xl lg:text-5xl  h-full font-bold py-4 text-transparent bg-clip-text bg-gradient-to-r from-[#115db4] to-[#359fce] mb-4">
          {title}
        </h3>

        <p className="lg:text-lg text-normal  text-blue-800 font-semibold">
          {desc1}
          <br />
          {desc2}
          <br /><br />
          {desc3}
          <br /><br />
          <br />
          <span className='text-blue-800 font-bold'>Empowering the Important</span>
        </p>
      </div>



    </div>
  )
}

export default SubIntro1
