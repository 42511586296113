import React from 'react';

// SubIntro2 component
function SubIntro2({ title, desc1, desc2, desc3,videoLink }) {
  return (
    <div
      className="m-auto p-4 px-6 md:px-8 lg:px-8 md:py-8 h-5/6 lg:max-w-[1320px] flex flex-col items-center lg:items-start"
      id="about"
    >
      {/* Left Section: Text Content */}
      <div className="flex flex-col justify-center items-start text-start py-8 w-full">
        <h3 className="text-3xl md:text-4xl lg:text-5xl xl:text-6xl font-bold py-2 text-transparent bg-clip-text bg-gradient-to-r from-[#115db4] to-[#359fce]">
          {title}
        </h3>

        {/* Flex Container for Paragraph and Video */}
        <div className="flex flex-col lg:flex-row items-center lg:items-start w-full mt-4">
          {/* Paragraph Content */}
          <p className="text-normal lg:text-lg text-blue-800 font-semibold lg:w-1/2">
            {desc1}
            <br />
            {desc2}
            <br />
            <br />
            {desc3}
            <br />
            <br />
            <br />
          
          </p>

          {/* YouTube Video Box */}
          <div className="lg:mt-2 lg:ml-8 lg:w-1/2 w-full h-[300px] lg:h-[400px] flex justify-center items-center">
            <iframe
              className="w-full h-full"
              src={videoLink} // Example video, replace with your video URL
              title="Video of The Product"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubIntro2;
