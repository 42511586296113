import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'

import CardSub from '../../components/CardSub'

import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import SubIntro2 from '../../components/SubIntro2'
import FeaturesProductCard from '../../components/FeaturesProductCard'
import MirrorFeaturesProductCard from '../../components/MirrorFeaturesProductCard'

import FMS1 from '../../images/Products/FMS1.jpg'
import FMS2 from '../../images/Products/FMS2.jpg'
import FMS3 from '../../images/Products/FMS3.jpg'
import FMS4 from '../../images/Products/FMS4.jpg'


function FMS_Product() {

 


  return (
    <div className='bg-white '>
    <HeroSubPages
      title="Facility 
      Management System"
      img={FMS1}
      desc="Our Facility Management System (FMS) optimizes the management of your facility’s operations. It helps streamline maintenance, track resources, and manage space efficiently. With our FMS, you can enhance operational efficiency, reduce costs, and ensure a well-maintained and functional environment." />



    <SubIntro2 title={'Standardize, promote, and regulate your facility with a single click!'}
      desc1={'Whether you operate a hospital, corporate office, educational institution, or other large facility, our Facility Management System (FMS) is intended to streamline routines and optimize your day-to-day activities, which will significantly increase efficiency.'}
      desc2={"Asset Management: Manage and track all of your facility's assets without any difficulty. Obtain the status of each asset; know where every item is located at any given time."}
      videoLink={'https://player.vimeo.com/video/1004594776?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22%20frameborder=%220%22%20allow=%22autoplay;%20fullscreen;%20picture-in-picture;%20clipboard-write'}

    />

    {/* Features of the  Product */}

    <MirrorFeaturesProductCard
      img={FMS2}
      title={'Maintenance Scheduling'}
      desc1={'Use scheduled maintenance to carry out tasks regularly, thus ensuring that your facilities are always in perfect condition. Avoiding delays and expensive repairs becomes easier with maintenance alerts.'}
    />

    <div className="md:block hidden" >
      <FeaturesProductCard
        img={FMS3}
        title={'Space Management'}
        desc1={"Maximizing productivity by re-scheduling with the help of smart space management technology. Plan and assign space adequately to achieve the company's changing needs."}
      />
    </div>
    <div className="md:hidden block" >

      <MirrorFeaturesProductCard
        img={FMS3}
        title={"Space Management"}
        desc1={"Maximizing productivity by re-scheduling with the help of smart space management technology. Plan and assign space adequately to achieve the company's changing needs."} />

    </div>

    <MirrorFeaturesProductCard
      img={FMS4}
      title={'Work Order Management'}
      desc1={'Begin a work order and allocate it solely by using our easy process. Work orders will be resolved efficiently over time and response time will be improved.'}
    />

    <CardSub />
    {/* <StatBox stats={statsData} /> */}
    {/* <PortfolioSub /> */}
    <TeamCards />
    {/* <SuopportCompanies/> */}


    <Footer />

  </div>
  )
}

export default FMS_Product
