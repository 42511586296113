import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import TreePlant from '../images/tree.jpg'
import Server from '../images/servers.jpg'
import Partner from '../images/partner.jpg'
import Img from '../images/male-doctor-taking-notes-while-working-with-colleagues-medical-clinic.jpg'

const PortfolioSub = () => {


    const [blogs, setBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                // Replace with your actual URL
                const response = await fetch('/api/blogslist.php?limit=12');

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                // Shuffle and select 4 random blogs
                const shuffled = data.sort(() => 0.5 - Math.random());
                setBlogs(shuffled.slice(0, 6));
            } catch (error) {
                console.error('Error fetching blogs:', error);
            }
        };

        fetchBlogs();
    }, []);



    return (
        <>
            <div className="px-4 my-4 py-4 lg:max-w-[1350px] mx-auto" id='portfolio'>
                <h2 className="my-2 text-start text-3xl text-blue-900 font-bold">Stay Updated</h2>

                <div className="px-4 mt-12" data-aos="fade-down" data-aos-delay="300">
                    <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                        {blogs.map((blog, index) => (
                            <div key={index} className="bg-white overflow-hidden shadow-lg ">
                                {/* Image */}
                                <img src={blog.image} alt="Card" className="w-full h-64 object-cover" />

                                {/* Content */}
                                <div className="p-6 my-6">
                                    <h4 className="font-bold text-start text-2xl mb-4 text-blue-900 overflow-hidden text-ellipsis whitespace-nowrap">
                                        {blog.title}
                                    </h4>

                                    <div className="text-start mt-6">
                                        <Link to={`/Blogs/${blog.id}`} className="px-6 py-3 font-bold bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 transition duration-300 text-white ">
                                            Learn More
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PortfolioSub;