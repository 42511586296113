import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'
import SSSimg from '../../images/services/SSS.jpg'
import StatBox from '../../components/StatBox'

import Icon1 from '../../images/services/svg111.svg'; // Import SVG as ReactComponent
import Icon2 from '../../images/services/svg222.svg';
import Icon3 from '../../images/services/svg333.svg';
import Icon4 from '../../images/services/svg444.svg';
import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'

function Fos() {

  const statsData = [
    {
      svg: <img className='w-28 h-28' src={Icon1} alt="Projects Completed" />,
      title: 'Clients',
      value: 120,
      desc: "clients worldwide"

    },
    {
      svg: <img className='w-28 h-28' src={Icon2} alt="Projects Completed" />,
      title: 'Business Technologies',
      value: 45,
      desc: "dedicated business technologists"
    },
    {
      svg: <img className='w-28 h-28' src={Icon3} alt="Projects Completed" />,
      title: 'Labs',
      value: 30,
      desc: "Innovation Labs Providing Collaborative Co-Creation Services"
    },
    {
      svg: <img className='w-28 h-28' src={Icon4} alt="Projects Completed" />,
      title: 'Contries',
      value: 10,
      desc: "Countries We Serve"

    }
  ];


  return (
    <div className='bg-gray-100'>
      <HeroSubPages
        title="Finance and other support Solution"
        img={SSSimg}
        desc="Enhance your financial management and support systems with our tailored solutions. We provide expert guidance to streamline processes, improve efficiency, and drive strategic growth, ensuring that your resources are utilized effectively and contribute to your organization's long-term success." />


      <SubIntro1 title={'Transform Your Business with Smart Finance and Support'} 
      desc1={'we know that strong financial management and robust operational support are vital to your business’s success. Our Finance and Support Solutions are meticulously crafted to streamline your financial processes, optimize your resource management, and reinforce your operational backbone.'} 
      desc2={'we empower you to manage your finances with precision while ensuring that your support systems are fully optimized to meet your business goals.'} 
      desc3={'Our goal is to provide you with the optimal resources to achieve your goals. We will help you align your financial resources with those of your business. To get the most out of your investment, you should paste in over a hundred words. For best results, you should also include at least five paragraphs. The strategic allocation of your investments and financial resources will help you achieve your objectives and align your financial resources with those of your business.'}/>
     
      <CardSub/>
      <StatBox stats={statsData} />
      <PortfolioSub/>
      {/* <TeamCards/> */}
      {/* <SuopportCompanies/> */}


    <Footer/>

    </div>
  )
}

export default Fos
