import React from 'react';
import Banner_1 from '../videos/IT_service_2.mp4';
import Banner_2 from '../videos/HMS.mp4';
import Banner_3 from '../videos/factory.mp4';
import Banner_4 from '../videos/fintech.mp4';

const VideoCarousel = ({ activeIndex }) => {
  const videos = [
    { src: Banner_1 }, 
    { src: Banner_2 }, 
    { src: Banner_3 },
    { src: Banner_4 }
  ];

  return (
    <>
      {videos.map((video, index) => (
        <video
          key={index}
          className={`absolute top-0 left-0 w-full h-[64vh] md:h-[64vh] lg:h-[64vh] xl:h-[68vh] object-cover transition-opacity duration-500 ${activeIndex === index ? 'opacity-100' : 'opacity-0'}`}
          src={video.src}
          autoPlay
          loop
          muted
        />
      ))}
    </>
  );
};

export default VideoCarousel;
