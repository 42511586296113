import React, { useEffect, useState } from 'react';
import NavBar from '../components/Navbar/NavBar';
import { useParams } from 'react-router-dom';

function Blog() {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBlog = async () => {
      try {
        const response = await fetch(`/api/blogcontent.php?id=${id}`);
        console.log('Response:', response);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Data:', data);

        // Assuming the data is an array and we need the first item
        if (Array.isArray(data) && data.length > 0) {
          setBlog(data[0]);
        } else {
          setError(new Error('No blog data found.'));
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchBlog();
  }, [id]);




  

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  if (!blog) return <p>No blog found.</p>;

  return (
    <>
      <div className="hero" id='hero'>
        <NavBar />
        <div className="m-auto p-4 md:py-8 lg:max-w-[1320px]">
          <div className="relative overflow-hidden mt-24">
            <img 
              className="w-full h-[620px] object-cover transition-opacity duration-500" 
              src={blog.image} 
              alt={blog.title || "Blog Image"} 
            />
          </div>
          <div className="flex flex-col justify-center items-start text-start py-8">
            <h1 className='text-black font-bold md:text-4xl text-xl mb-4 blog-content'>{blog.title}</h1>
            <div 
              className="text-lg"
              dangerouslySetInnerHTML={{ __html: blog.body }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
