import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'
import CMSImg from '../../images/services/CMS1.jpg'
import StatBox from '../../components/StatBox'

import Icon1 from '../../images/services/svg1.svg'; // Import SVG as ReactComponent
import Icon2 from '../../images/services/svg2.svg';
import Icon3 from '../../images/services/svg3.svg';
import Icon4 from '../../images/services/svg4.svg';
import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'

function Cms() {

  const statsData = [
    {
      svg: <img className='w-28 h-28' src={Icon1} alt="Projects Completed" />,
      title: 'Global Users',
      value: 35,
      desc: "Empowering Users Worldwide"

    },
    {
      svg: <img className='w-28 h-28' src={Icon2} alt="Projects Completed" />,
      title: 'Experts',
      value: 27,
      desc: "Serving a Global Network of Experts"
    },
    {
      svg: <img className='w-28 h-28' src={Icon3} alt="Projects Completed" />,
      title: 'Partners',
      value: 15,
      desc: "Building Strong Global Partnerships for Sustainable Success"
    },
    {
      svg: <img className='w-28 h-28' src={Icon4} alt="Projects Completed" />,
      title: 'Satisfied Customers',
      value: 100,
      desc: "Ensuring Customer Satisfaction with Consistent Quality and Support"

    }
  ];


  return (
    <div className='bg-gray-100'>
      <HeroSubPages
        title="Capacity Management Services"
        img={CMSImg}
        desc="Optimize your organization's resources and capabilities with our advanced Capacity Management System. Enhance planning, forecasting, and decision-making processes to ensure that your resources are utilized efficiently and effectively." />



      <SubIntro1 title={'Enhanced Capacity Management'}
        desc1={'In today’s fast-paced digital world where digital transformation drives business success, Capacity Management has become a cornerstone for any organization looking to maintain a competitive edge. '}
        desc2={' Our Capacity Management System is designed to empower your business by aligning IT capacity with strategic goals,'}
        desc3={'ensuring you’re always prepared for what’s next. With the advanced analytics and forecasting tools, our Capacity Management System allows you to anticipate future demand and plan accordingly, avoiding the risks of over-provisioning or under-provisioning.'} />

      <CardSub />
      <StatBox title={'Empower Your Business with Optimized Resource Management'} stats={statsData} />
      <PortfolioSub />
      {/* <TeamCards /> */}
      {/* <SuopportCompanies/> */}


      <Footer />

    </div>
  )
}

export default Cms
