import React from 'react';
import Footer from '../components/Footer';
import NavBar from '../components/Navbar/NavBar';
import { Link } from 'react-router-dom';

function Page404() {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Navbar */}
      <NavBar />

      {/* Main content */}
      <main className="flex-grow flex items-center justify-center bg-gray-100">
        <div className="text-center p-6 bg-white shadow-lg rounded-lg">
          <h1 className="text-6xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-blue-700 to-blue-400 mb-6">
            404
          </h1>
          <p className="text-xl mb-6 text-gray-700">
            Oops! The page you’re looking for doesn’t exist.
          </p>
          <Link to="/" className="px-6 py-3 font-bold bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 transition duration-300 text-white rounded-lg">
            Go to Home Page
          </Link>
        </div>
      </main>

      {/* Footer */}
      <Footer />
    </div>
  );
}

export default Page404;
