import React from 'react'
import HeroSubPages from '../../components/HeroSubPages'
import SubIntro1 from '../../components/SubIntro1'
import BPSImg from '../../images/services/BPS.jpg'
import StatBox from '../../components/StatBox'

import CardSub from '../../components/CardSub'
import SuopportCompanies from '../../components/SuopportCompanies'
import PortfolioSub from '../../components/PortfolioSub'
import TeamCards from '../../components/TeamCards'
import Footer from '../../components/Footer'
import SubIntro2 from '../../components/SubIntro2'
import FeaturesProductCard from '../../components/FeaturesProductCard'
import MirrorFeaturesProductCard from '../../components/MirrorFeaturesProductCard'
import Image from '../../images/AboutUs1.jpg'

import PAMS2 from '../../images/Products/PAMS2.jpg'
import PAMS3 from '../../images/Products/PAMS3.jpg'
import PAMS4 from '../../images/Products/PAMS4.jpg'


function PAMS_Product() {

 


  return (
    <div className='bg-white '>
    <HeroSubPages
      title="Payroll and Appraisal Management System"
      img={BPSImg}
      desc="Our Payroll and Appraisal Management System (PAMS) is designed to streamline and automate employee compensation and performance evaluation processes. This comprehensive system handles payroll processing with precision, including salary calculations, tax deductions, and compliance with labor laws." />



    <SubIntro2 title={'Streamline payroll and appraisals with our integrated system'}
      desc1={'Managing payroll and employee appraisals can be cumbersome and intensive. With our combine of technology with advanced and user-friendly design'}
      desc2={'our Income Payroll and Appraisal Management System manage these crucial processes on behalf of your organization with accuracy and ease.'}
      desc3={'Our system simplifies payroll and appraisal management by automating complex processes and providing real-time insights.'}
      videoLink={'https://player.vimeo.com/video/1004594776?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479%22%20frameborder=%220%22%20allow=%22autoplay;%20fullscreen;%20picture-in-picture;%20clipboard-write'}

    />

    {/* Features of the  Product */}

    <MirrorFeaturesProductCard
      img={PAMS2}
      title={'Tax Compliance'}
      desc1={'Comply with tax regulations without penalties; our system automatically calculates tax withholdings and generates tax reports every payroll period.'}
    />

    <div className="md:block hidden" >
      <FeaturesProductCard
        img={PAMS3}
        title={'Accuracy'}
        desc1={'Accuracy in payroll calculation and really fair assessments of employee performance rely on algorithms and accurate, up-to-date employee data.'}
      />
    </div>
    <div className="md:hidden block" >

      <MirrorFeaturesProductCard
        img={PAMS3}
        title={"Accuracy"}
        desc1={"Accuracy in payroll calculation and really fair assessments of employee performance rely on algorithms and accurate, up-to-date employee data."} />

    </div>

    <MirrorFeaturesProductCard
      img={PAMS4}
      title={'Automated Payroll Processing'}
      desc1={'Calculate and process employee salaries accurately, regardless of complexity (overtime, deductions, and bonuses).'}
    />

    <CardSub />
    {/* <StatBox stats={statsData} /> */}
    {/* <PortfolioSub /> */}
    <TeamCards />
    {/* <SuopportCompanies/> */}


    <Footer />

  </div>
  )
}

export default PAMS_Product
