import React from 'react'

import logo1 from '../images/logos/logo1.png'
import logo2 from '../images/logos/logo2.png'
import logo3 from '../images/logos/logo3.png'
import logo4 from '../images/logos/logo4.png'
import logo5 from '../images/logos/logo4.png'
import logo6 from '../images/logos/logo6.png'


function SuopportCompanies() {

    const logos = [
        {
            image: logo1,

        },
        {
            image: logo2,

        },
        {
            image: logo3,

        },
        {
            image: logo4,

        },
        {
            image: logo5,

        },
        {
            image: logo6,

        },
    ];


    return (

        <div className='bg-gray-200 my-4 py-4 mt-6'>
            <div className=" lg:max-w-[1350px] mx-auto " id="portfolio">
                <h2 className="my-2 text-start text-3xl text-blue-900 font-bold">Harnessing the Power of We</h2>

                <div className="px-4 mt-12">

                    <div className="lg:max-w-[1320px] max-auto  grid lg:grid-cols-6 md:grid-cols-4 grid-cols-2 gap-6">
                        {logos.map((logo, index) => (

                            <div key={index} className='p-6'>

                                <img
                                    src={logo.image}
                                    alt="Background"
                                    className="w-full h-full   rounded-t transition-transform duration-1000 ease-in-out group-hover:scale-[1.15]"
                                />

                            </div>

                        ))}
                    </div>


                </div>
            </div>
        </div>


    )
}

export default SuopportCompanies
