import React from 'react';
import { Link } from 'react-router-dom';
import TreePlant from '../images/tree.jpg';
import Server from '../images/servers.jpg';
import Partner from '../images/partner.jpg';
import Img from '../images/male-doctor-taking-notes-while-working-with-colleagues-medical-clinic.jpg';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const CardSub = () => {
  const cards = [
    {
      image: Server,
      title: 'IT & Cosulting Services',
      description: 'From cloud migration to cybersecurity and data analytics.',
      link: "/ITSC-services"
    },
    {
      image: TreePlant,
      title: 'Eco-Friendly Solutions',
      description: 'Providing innovative solutions for sustainability and reduced environmental impact.',
      link: "/AA-industries"
    },
    {
      image: Partner,
      title: 'Partnership Growth',
      description: 'Driving growth through strategic partnerships and collaborations.',
      link: "/BPS-services"
    },
    {
      image: Img,
      title: 'Healthcare Innovations',
      description: 'Modern healthcare solutions and patient management.',
      link: "/HC-industries"
    },
  ];

  return (
    <div className="my-4 py-4 lg:max-w-[1350px] mx-auto" id="portfolio">
      <h2 className="px-6  text-start text-3xl text-blue-900 font-bold">Stay Updated</h2>

      <div className="px-4 mt-12">
        <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-5">
          {cards.map((card, index) => (
            <div
              key={index}
              className="relative lg:max-w-[1040px] mx-auto overflow-hidden shadow-2xl group"
            >
              {/* Background Image */}
              <div className="relative w-full h-[400px]">
                {/* <img
                  src={card.image}
                  alt="Background"
                  className="w-full h-full object-cover rounded-t transition-transform duration-1000 ease-in-out group-hover:scale-[1.15]"
                /> */}

                <LazyLoadImage
                  alt="Background"
                  src={card.image}  // Use optimized images              
                  effect="blur"
                  className="w-full h-full object-cover rounded-t transition-transform duration-1000 ease-in-out group-hover:scale-[1.15]" // Add your custom class here
                />


               
                {/* Fading Gradient Effect */}
                <div className="absolute inset-0 bg-gradient-to-r from-gray-900 via-gray-500 to-transparent opacity-40 pointer-events-none transition-opacity duration-1000 ease-in-out group-hover:opacity-0"></div>
              </div>

              {/* Text Box at Bottom with Shadow */}
              <div className="absolute bottom-0 w-full  left-1/2 transform -translate-x-1/2 mb-6 bg-gray-900 bg-opacity-50 text-white p-6 shadow-lg  transition-all duration-300 ease-in-out group-hover:shadow-2xl">
                <h2 className="font-bold text-2xl mb-2">{card.title}</h2>
                <p className="text-sm mb-4">{card.description}</p>
                <Link
                  to={card.link}
                  className="px-4 py-2 font-bold bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 transition duration-300 text-white "
                >
                  Learn More
                </Link>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CardSub;